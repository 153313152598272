
import { Options, Vue } from "vue-class-component"

import { AxiosResponse } from "axios"
import axios from "axios"

import ValidateToken from "@/components/ValidateToken.vue"

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'

import { faRotateLeft, faTrash } from '@fortawesome/free-solid-svg-icons'
library.add(faRotateLeft, faTrash);

class UserDetails {
	firstname = ''
	lastname = ''
	email = ''
	password = ''
	lang_code = 'FR'
}

class UserSubDate {
	from = ''
	to = ''
}

class UserSubInfos {
	dates = new UserSubDate
	auto = false
}

class UserHeadsetInfos {
	max_headsets = 0
	last_activity = ''
}

class UserInfos {
	static instance = 0

	uniqid: string = '' + (UserInfos.instance++)
	enabled = true

	details = new UserDetails()
	sub = new UserSubInfos()
	headsets = new UserHeadsetInfos()
}

class UserCollection extends Array<UserInfos> {}

class UserCollectionAPIResponse {
	result = 0
	content?: {
		auth: { token: string }
		users: UserCollection,
		error: { 
			code: string,
			message: string
		}
	}
}

class SelectedUserMatch {
	infos: UserInfos | null = null
	previous: UserInfos | null = null

	element: HTMLElement | null = null
	column: HTMLTableCellElement | null = null
}

@Options({
	components: {
		FontAwesomeIcon,
		ValidateToken,
	}
})

export default class Dashboard extends Vue {
	apiError : string[] = []

	users: UserCollection = []
	resultUsers: UserCollection = []

	editedUser = new SelectedUserMatch()

	addedUser = new UserInfos()

	classToSelectRow = "selected"
	selectedUsers: Array<string> = []

	researchFieldValue = ""

	loginUri = '/login'

	languageAvailable = [
		'FR',
		'EN'
	]

	OnValidateHandler(success: boolean, msg = ''): void {
		if(!success)
		{
			this.apiError.push(msg)
			this.$router.push(this.loginUri)
		}
	}

	mounted() : void {
		this.GetUserList()
	}

	GetUserList() : void {
		const uri = this.$store.state.api.url + '/users'
		
		const options = {
			headers: {
				'Authorization': 'Bearer <' + this.$store.state.api.auth.token + '>'
			}
		}
		
		axios.get(uri, options).then((response: AxiosResponse) => {
			const apiResponse = response.data as UserCollectionAPIResponse

			if(!apiResponse.content)
				this.apiError.push('Une réponse du serveur est attendue mais aucune réponse n\'est parvenue')
			else if(apiResponse.content.error)
				this.apiError.push(apiResponse.content.error.message)
			else
				this.users = apiResponse.content.users
		}).catch((reason) => {
			if(reason.response.data)
				this.apiError.push(reason.response.data.content.error.message)
			else
				this.apiError.push(reason)
		})
	}

	SendNewUser() : boolean {
		const uri = this.$store.state.api.url + '/users'
		
		const options = {
			headers: {
				'Authorization': 'Bearer <' + this.$store.state.api.auth.token + '>'
			}
		}

		const body = new FormData()
		body.append('user_firstname', this.addedUser.details.firstname)
		body.append('user_lastname', this.addedUser.details.lastname)

		body.append('user_email', this.addedUser.details.email)
		body.append('user_password', this.addedUser.details.password)

		body.append('user_max_headsets', this.addedUser.headsets.max_headsets.toString())
		body.append('user_lang_code', this.addedUser.details.lang_code)
		
		axios.post(uri, body, options).then((response: AxiosResponse) => {
			const apiResponse = response.data as UserCollectionAPIResponse

			if(!apiResponse.content)
				this.apiError.push('Une réponse du serveur est attendue mais aucune réponse n\'est parvenue')
			else if(apiResponse.content.error)
				this.apiError.push(apiResponse.content.error.message)
			else
			{
				const addUserRowElement = document.getElementById('AddUserRow')

				if(addUserRowElement)
				{
					addUserRowElement.classList.add('hidden')
					this.addedUser = new UserInfos();	
				}

				document.getElementById('AddUserRow')?.classList

				this.GetUserList()
			}
		}).catch((reason) => {
			this.apiError.push(reason.response.data.content.error.message)
		})

		return false
	}

	CancelNewUser(e: MouseEvent) : boolean {
		e.preventDefault()

		document.getElementById('AddUserRow')?.classList.add('hidden')
		this.addedUser = new UserInfos();	

		return false
	}

	ResearchUser(e: KeyboardEvent) : boolean
	{
		this.resultUsers = []
		
		this.users.forEach(e => {
			if(this.researchFieldValue.includes(e.details.firstname))
				this.resultUsers.push(e)
			else if(this.researchFieldValue.includes(e.details.lastname))
				this.resultUsers.push(e)
			else if(this.researchFieldValue.includes(e.details.email))
				this.resultUsers.push(e)
			else if(this.researchFieldValue.includes(e.uniqid))
				this.resultUsers.push(e)
		})

		return false
	}

	OnMouseOverCellPassword(e: MouseEvent) : boolean {
		const eventTargetElement = (e.target as HTMLElement)

		const inputElement = eventTargetElement.firstElementChild as HTMLInputElement

		if(inputElement != null)
			this.ShowPassword(inputElement)

		return false
	}

	OnMouseOutCellPassword(e: MouseEvent) : boolean {
		const eventTargetElement = (e.target as HTMLElement)

		const inputElement = eventTargetElement.firstElementChild as HTMLInputElement

		if(inputElement != null)
			this.ShowPassword(inputElement, false)

		return false
	}

	ShowPassword(element: HTMLInputElement, show = true) : void {
		element.type = show ? 'text' : 'password'
	}
	
	GeneratePassword(e: MouseEvent, uniqid: string) : boolean {
		e.preventDefault()

		this.users.forEach(v => {
			if(v.uniqid == uniqid && v.details != null)
			{
				v.details.password = (Math.floor(Math.random() * 99999999) + 10000000).toString()
				this.UpdateUser(v)
				return false
			}
		})

		return false
	}
	
	CreatePassword(e: MouseEvent) : boolean {
		e.preventDefault()

		this.addedUser.details.password = (Math.floor(Math.random() * 99999999) + 10000000).toString()
		return false
	}

	OnMouseOverTableRow(e: MouseEvent, uniqid: string) : boolean {
		e.preventDefault()
		
		if(this.editedUser.previous != null)
			return false

		/// Désélection de l'élément précédement sélectionné
		if(this.editedUser.element != null && e.target != this.editedUser.element)
			this.SelectElement(this.editedUser.element, false)
		/// Sinon si l'élément survolé est déjà sélectionné, on abandonne
		else if(this.editedUser.element == e.target)
			return false

		/// Récupère les infos de l'élément survolé
		const infos = this.users.find(v => v.uniqid == uniqid)

		/// S'il y a pas d'infos, on quitte
		if(infos == null)
			return false

		this.editedUser.element = e.target as HTMLElement
		this.editedUser.infos = infos

		this.SelectElement(e.target as HTMLElement)

		return false
	}

	OnMouseExitTable(e: MouseEvent) : boolean {
		e.preventDefault()
			
		if(e.target == null)
			return false

		if(this.editedUser.element != null && this.editedUser.previous == null)
		{
			this.SelectElement(this.editedUser.element, false)

			this.editedUser.infos = null
			this.editedUser.element = null
		}

		return false
	}

	ShowAddUserRow(e: MouseEvent) : boolean {
		e.preventDefault()

		document.getElementById("AddUserRow")?.classList.remove("hidden")

		return false
	}

	OnMouseClickTableCell(e: MouseEvent, uniqid: string) : boolean {
		if((e.target as HTMLElement).tagName != "TD")
			return false
			
		e.preventDefault();

		if(uniqid != this.editedUser.infos?.uniqid || this.editedUser.column == (e.target as HTMLTableCellElement))
			return false

		if(this.editedUser.column != null)
		{
			this.SelectElement(this.editedUser.column, true, 'disabled')
			this.UpdateUser(this.editedUser.infos)
		}

		const element = (e.target as HTMLElement).firstElementChild as HTMLInputElement

		if(element == null)
			return false

		element.focus()
		this.SelectElement(element, false, 'disabled')

		this.editedUser.previous = this.editedUser.infos
		this.editedUser.column = e.target as HTMLTableCellElement

		return false
	}

	OnValidTableCellInput(e: KeyboardEvent) : boolean {
		if(!this.editedUser.column)
			return false

		e.preventDefault()

		const element = this.editedUser.column.firstElementChild as HTMLInputElement

		if(element == null)
			return false

		if(e.key == 'Enter' && this.editedUser.infos != null && this.editedUser.infos.details != null)
		{
			switch(element.name)
			{
				case 'Names':
					if(this.editedUser.infos.details != null)
					{
						const texts = element.value.split(' ')

						if(texts.length > 0)
							this.editedUser.infos.details.firstname = texts[0]
	
						if(texts.length > 1)
							this.editedUser.infos.details.lastname = texts[1]
					}
					break

				case 'Email':
					if(this.editedUser.infos.details != null)
						this.editedUser.infos.details.email = element.value
					break

				case 'Substart':
					if(this.editedUser.infos.sub != null)
						this.editedUser.infos.sub.dates.from = element.value
					break

				case 'Subend':
					if(this.editedUser.infos.sub != null)
						this.editedUser.infos.sub.dates.to = element.value
					break

				case 'MaxHeadsets':
					if(this.editedUser.infos.headsets != null)
						this.editedUser.infos.headsets.max_headsets = element.valueAsNumber
					break
			}

			this.UpdateUser(this.editedUser.infos)
		}
		else if(e.key == 'Escape' && this.editedUser.previous != null)
		{
			switch(element.name)
			{
				case 'Names':
					if(this.editedUser.previous.details != null)
						element.value = this.editedUser.previous.details.firstname + ' ' + this.editedUser.previous.details.lastname
					break

				case 'Email':
					if(this.editedUser.previous.details != null)
						element.value = this.editedUser.previous.details.email
					break

				case 'Substart':
					if(this.editedUser.previous.sub != null)
						element.value = this.editedUser.previous.sub.dates.from
					break

				case 'Subend':
					if(this.editedUser.previous.sub != null)
						element.value = this.editedUser.previous.sub.dates.to
					break

				case 'MaxHeadsets':
					if(this.editedUser.previous.headsets != null)
						element.valueAsNumber = this.editedUser.previous.headsets.max_headsets
					break
			}
		}
		else
			return false

		this.SelectElement(element, true, 'disabled')

		this.editedUser.previous = null
		this.editedUser.column = null

		element.blur()

		return false
	}

	OnBlurTableCellInput(e: FocusEvent) : boolean {
		if(!this.editedUser.column || !this.editedUser.previous)
			return false
			
		e.preventDefault()

		const element = this.editedUser.column.firstElementChild as HTMLInputElement
		this.SelectElement(element, true, 'disabled')

		switch(element.name)
		{
			case 'Names':
				if(this.editedUser.previous.details != null)
					element.value = this.editedUser.previous.details.firstname + ' ' + this.editedUser.previous.details.lastname
				break

			case 'Email':
				if(this.editedUser.previous.details != null)
					element.value = this.editedUser.previous.details.email
				break

			case 'Substart':
				if(this.editedUser.previous.sub != null)
					element.value = this.editedUser.previous.sub.dates.from !== '1970-01-01' ? this.editedUser.previous.sub.dates.from : ''
				break

			case 'Subend':
				if(this.editedUser.previous.sub != null)
					element.value = this.editedUser.previous.sub.dates.to !== '1970-01-01' ? this.editedUser.previous.sub.dates.to : ''
				break

			case 'Password':
				if(this.editedUser.previous.details != null)
					element.value = this.editedUser.previous.details.password
				break

			case 'MaxHeadsets':
				if(this.editedUser.previous.headsets != null)
					element.valueAsNumber = this.editedUser.previous.headsets.max_headsets
				break
		}

		this.editedUser.previous = null
		this.editedUser.column = null

		return false
	}

	OnChangeTableCellSelect(e: Event, uniqid: string) : void {
		const element = e.target as HTMLSelectElement

		this.users.forEach(e => {
			if(e.uniqid === uniqid)
			{
				this.editedUser.infos = e
				return
			}
		})
		
		if(!this.editedUser.infos)
			return

		switch(element.name)
		{
			case 'Enabled':
				if(this.editedUser.infos.details != null)
					this.editedUser.infos.enabled = element.value === 'true'
				break

			case 'Lang':
				if(this.editedUser.infos.details != null)
					this.editedUser.infos.details.lang_code = element.value
				break
		}

		this.UpdateUser(this.editedUser.infos)
		this.editedUser.infos = null
	}

	UpdateUser(userinfos: UserInfos) : void {
		if(!this.editedUser.infos)
			return

		const uri = this.$store.state.api.url + '/users/' + userinfos.uniqid
		
		const options = {
			headers: {
				'Authorization': 'Bearer <' + this.$store.state.api.auth.token + '>'
			}
		}

		const body = new FormData()
		body.append('user_firstname', this.editedUser.infos.details.firstname)
		body.append('user_lastname', this.editedUser.infos.details.lastname)

		body.append('user_email', this.editedUser.infos.details.email)
		body.append('user_password', this.editedUser.infos.details.password)

		body.append('user_max_headsets', this.editedUser.infos.headsets.max_headsets.toString())
		body.append('user_lang_code', this.editedUser.infos.details.lang_code)
		body.append('user_enabled', this.editedUser.infos.enabled.toString())

		body.append('sub_date_from', this.editedUser.infos.sub.dates.from)
		body.append('sub_date_to', this.editedUser.infos.sub.dates.to)
		
		axios.post(uri, body, options).then((response: AxiosResponse) => {
			const apiResponse = response.data as UserCollectionAPIResponse

			if(apiResponse.result === 1)
			{
				if(apiResponse.content)
					this.GetUserList()
				else
					this.apiError.push("Le serveur a envoyé une réponse qui n'était pas attendue: --unknown-success")
			}
			else if(apiResponse.content && apiResponse.content.error)
				this.apiError.push(apiResponse.content.error.message)
			else
				this.apiError.push("Le serveur a envoyé une erreur qui n'était pas attendue: --unknown-error")

		}).catch((reason) => {
			this.apiError.push(reason.response.data.content.error.message)
		})
	}

	DeleteUser(e: MouseEvent, uniqid: string) : boolean {
		e.preventDefault()

		const uri = this.$store.state.api.url + '/users/' + uniqid
		
		const options = {
			headers: {
				'Authorization': 'Bearer <' + this.$store.state.api.auth.token + '>'
			}
		}
		
		axios.delete(uri, options).then((response: AxiosResponse) => {
			const apiResponse = response.data as UserCollectionAPIResponse

			if(apiResponse.result === 1)
			{
				if(!apiResponse.content)
					this.GetUserList()
				else
					this.apiError.push("Le serveur a envoyé une réponse qui n'était pas attendue: --unknown-success")
			}
			else if(apiResponse.content && apiResponse.content.error)
				this.apiError.push(apiResponse.content.error.message)
			else
				this.apiError.push("Le serveur a envoyé une erreur qui n'était pas attendue: --unknown-error")

		}).catch((reason) => {
			this.apiError.push(reason.response.data.content.error.message)
		})

		return false
	}

	WatchDevices(e: MouseEvent, uniqid: string) : boolean {
		e.preventDefault()
		this.$router.push('/dashboard/' + uniqid + '/devices')
		return false
	}

	WatchLogs(e: MouseEvent, uniqid: string) : boolean {
		e.preventDefault()
		this.$router.push('/dashboard/' + uniqid + '/logs')
		return false
	}

	SelectElement(element: HTMLElement, select = true, className = 'selected') : void {
		if(select && !element.classList.contains(className))
			element.classList.add(className)
		else if(!select && element.classList.contains(className))
			element.classList.remove(className)
	}
}
