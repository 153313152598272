
import { Options, Vue } from "vue-class-component"

import { AxiosResponse } from "axios"
import axios from "axios"

import ValidateToken, { ValidateAPIResponse } from "@/components/ValidateToken.vue"

@Options({
	components: {
		ValidateToken
	},
	computed: {
		Logo() {
			return this.logoSrc
		}
	}
})

export default class Home extends Vue {
	username = ""
	usernamePlaceholder = "Identifiant"

	password = ""
	passwordPlaceholder = "Mot de passe"

	submitBtnValue = "Valider"
	submitting = false

	errorLabel = ""

	authSuccessUri = '/dashboard'

	mounted(): void {
		window.setTimeout(() => { document.getElementById("LoginForm")?.classList.add("fadein"); }, 200);
	}

	OnSubmitClickHandler(e: UIEvent): boolean {
		e.preventDefault()
		this.submitting = true

		const uri = this.$store.state.api.url + '/auth/validate'
		var body = new FormData()
		
		body.append('username', this.username)
		body.append('password', this.password)
		
		axios.post(uri, body)
		.then((response: AxiosResponse) => {
			const apiResponse = response.data as ValidateAPIResponse

			if(apiResponse.content.error)
			{
				this.errorLabel = apiResponse.content.error.message
				return
			}

			this.$store.commit('SetToken', apiResponse.content.auth.token)
			localStorage.setItem('token', apiResponse.content.auth.token)

			this.$router.push(this.authSuccessUri)
		})
		.catch((error) => {
			const apiResponse = error.response.data as ValidateAPIResponse
			this.errorLabel = apiResponse.content.error.message
		})
		.finally(() => {
			window.setTimeout(() => this.submitting = false, 1000)	
		})

		return false
	}

	OnValidateHandler(success: boolean, msg = ''): void {
		if(!success)
		{
			localStorage.removeItem('token')
			this.errorLabel = msg
		}
		else
			this.$router.push(this.authSuccessUri)
	}
}
