import { createStore } from "vuex";

export default createStore({
	state: {
		api: {
			auth: {
				token: ''
			},
			
			url: process.env.NODE_ENV === 'production' ? 'https://api.manitou-group.lacomdigitale.com/admin/v1' : 'http://localhost/manitou/admin/v1' 
		},
	},
	mutations: {
		SetToken(state, value)
		{ state.api.auth.token = value; }
	},
	actions: {},
	modules: {},
});
