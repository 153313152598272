
import { Options, Vue } from "vue-class-component"

import { AxiosResponse } from "axios"
import axios from "axios"

import ValidateToken from "@/components/ValidateToken.vue"

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'

import { faArrowLeft, faTrash } from '@fortawesome/free-solid-svg-icons'
library.add(faArrowLeft, faTrash);

class LogInfos {
	static instance = 0

	uniqid: string = '' + (LogInfos.instance++)
	datetime = ''
	
	ip = ''
	os = ''
	app_version = ''

	device_name = '-'
	device_model = '-'
	device_uniqid = '-'
}

class LogCollection extends Array<LogInfos> {}

class LogCollectionAPIResponse {
	result = 0
	content?: {
		auth: { token: string }
		logs: LogCollection,
		error: {
			code: string,
			message: string
		}
	}
}

@Options({
	components: {
		FontAwesomeIcon,
		ValidateToken,
	},

	computed: {
		UUID() {
			return this.$route.params.uuid
		}
	}
})

export default class Logs extends Vue {
	apiError : string[] = []

	logs: LogCollection = []
	resultDevices: LogCollection = []

	researchFieldValue = ""
	loginUri = '/login'
	dashboardUri = '/dashboard'

	OnValidateHandler(success: boolean, msg = ''): void {
		if(!success)
		{
			this.apiError.push(msg)
			this.$router.push(this.loginUri)
		}
	}

	mounted() : void {
		this.GetLogList()
	}

	GoBack(e: MouseEvent) : boolean {
		e.preventDefault()

		this.$router.push(this.dashboardUri)

		return false
	}

	GetLogList() : void {
		const uri = this.$store.state.api.url + '/users/' + this.$route.params.uuid + '/logs'
		console.log(uri)

		const options = {
			headers: {
				'Authorization': 'Bearer <' + this.$store.state.api.auth.token + '>'
			}
		}
		
		axios.get(uri, options).then((response: AxiosResponse) => {
			const apiResponse = response.data as LogCollectionAPIResponse

			if(!apiResponse.content)
				this.apiError.push('Une réponse du serveur est attendue mais aucune réponse n\'est parvenue')
			else if(apiResponse.content.error)
				this.apiError.push(apiResponse.content.error.message)
			else
				this.logs = apiResponse.content.logs
		}).catch((reason) => {
			this.apiError.push(reason.response.data.content.error.message)
		})
	}
}
