
import axios, { AxiosResponse } from 'axios';
import { Options, Vue } from 'vue-class-component'
import { ValidateAPIResponse } from './components/ValidateToken.vue';

@Options({
	computed: {
		backgroundUrl() {
			return this.$store.state.styles.background.url
		}
	}
})

export default class App extends Vue {
	isInMaintenance = false
	appPaddingTop = "4rem"

	mounted() : void {
		document.title = 'Manitou Virtual Factory - Admin dashboard'

		if(this.isInMaintenance)
			this.$router.push("/maintenance");

		if(document.body.classList.contains("init-fadein"))
		{
			if(!document.body.classList.contains("fadein"))
				window.setTimeout(() => { document.body.classList.add("fadein"); }, 200);
			else
			{
				document.body.classList.remove("fadein");
				window.setTimeout(() => { document.body.classList.add("fadein"); }, 1400);
			}
		}

		this.$router.beforeResolve((to) => {
			this.$store.commit('SetToken', localStorage.getItem('token'))

			if(this.$store.state.api.auth.token && this.$store.state.api.auth.token.trim().length > 0)
			{				
				const uri = this.$store.state.api.url + '/auth/refresh'
				const options = {
					headers: { 'Authorization': 'Bearer <' + this.$store.state.api.auth.token.trim() + '>' }
				}

				axios.post(uri, {}, options)
				.then((response: AxiosResponse) => {
					const apiResponse = response.data as ValidateAPIResponse

					if(apiResponse.content.auth.token)
						localStorage.setItem('token', apiResponse.content.auth.token)
					else
					{
						localStorage.removeItem('token')
						this.$router.push('/login')
					}
				})
				.catch((error) => {
					localStorage.removeItem('token')
					this.$router.push('/login')
				})
			}
			else if(to.path !== '/')
				this.$router.push('/login')
		})
	}

	OnHeaderExit(e: MouseEvent) : boolean {
		e?.preventDefault()

		this.$store.commit('SetAppHeaderFullscreen', false)
		this.$store.commit('SetAppHeaderNavigation', true)
		this.$store.commit('SetAppOpened', true)

		return false
	}

	static _(id: string) : HTMLElement | null { return document.getElementById(id); }
}
